<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button class="ml-4" />
    <div class="mt-1 row px-3 mx-0 manage-users-show">
      <div class="col-12 mb-4 d-flex justify-content-between">
        <h1>{{ `View Info` }}</h1>
        <div>
          <base-button type="white" size="sm" @click="resendLoginDetails" :disabled="loaders.email" v-if="!loaders.fetching && user.program_id > 0 && user.opened_app < 1 ">Resend Login Email</base-button>
          <base-button type="white" size="sm" @click="resendLoginDetails" :disabled="loaders.email" v-else-if="!loaders.fetching">Send Reset Password Link</base-button>
        </div>
      </div>
      <div class="col-6">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" id="basic-info-card"
          v-loading="loaders.fetching">
          <div slot="header" class="d-flex justify-content-between">
            <div>
              <h1 style="min-height: 40px " class="mt-4 ml-2 manage-user-name">
                {{ user.first_name }}
                {{ user.last_name }}
              </h1>
              <div class="d-flex flex-row">
                <p class="mb-0 ml-2 manage-user-email">
                  {{ user.email }}
                </p>
                <p class="ml-2 manage-user-status pl-3 pr-3 pt-1 pb-1" :class="user.status === 0 ? 'inactive' : 'active'">
                  {{ getStatus }}
                </p>
              </div>
            </div>
            <router-link :to="{ name: 'admin.manage-users.edit', params: { id: user.id } }">
              <base-button type="dark-blue" outline class=" py-2 px-4 font-weight-normal">
                Edit
              </base-button>
            </router-link>
          </div>

          <h2 class="mt-3 pl-4 pt-4 manage-user-detail-heading border-top">BASIC
            INFO</h2>

          <div class="row pt-2 pl-4">
            <div class="col-6">
              <p class="manage-user-detail-text">DOB</p>
              <p class="manage-user-detail-text">Phone</p>
              <p class="manage-user-detail-text">Height</p>
              <p class="manage-user-detail-text">Join Date</p>
              <p class="manage-user-detail-text">Last Login</p>
              <p class="manage-user-detail-text">Last Push Notification</p>
              <p class="manage-user-detail-text">MRN Number</p>
              <p class="manage-user-detail-text">Created By</p>
            </div>
            <div v-if="user != []" class="col-6">
              <p class="black_p">{{ user.dob ? formatDate(user.dob) : '-' }}</p>
              <p class="black_p">{{ user.phone ? user.phone : '-' }}</p>
              <p class="black_p">{{ user.hight ? user.hight : '-' }}</p>
              <p class="black_p">{{ getProgramJoinDate }}</p>
              <p class="black_p">{{ user.last_used ? formatDate(user.last_used) : '-' }}</p>
              <p class="black_p">
                {{ user.last_push_notification ? formatDate(user.last_push_notification) : '-' }}
              </p>
              <p class="black_p">
                {{ user.mrn_number ? user.mrn_number : '-' }}
              </p>
              <p class="black_p">{{ user.created_by ? user.created_by : 'N/A' }}</p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6">
        <card class="no-border-card card-weight" body-classes="px-0 pb-1" footer-classes="pb-2"
          v-loading="loaders.fetching">
          <div slot="header">
            <h2 class="mt-2 manage-user-detail-heading">WEIGHT</h2>
          </div>

          <div class="row mt--4 pl-4">
            <div class="col-6 pl-3">
              <p class="manage-user-detail-text">Starting Weight</p>
              <p class="manage-user-detail-text">Current Weight</p>
              <p class="manage-user-detail-text">Target Weight</p>
            </div>
            <div class="col-6 pt-0 pl-3">
              <p class="black_p">
                {{ getStartingWeight }}
              </p>
              <p class="black_p">
                {{ getCurrentWeight }}
              </p>
              <p class="black_p mb-4">
                {{ getTargetWeight }}
              </p>
            </div>
          </div>

        </card>

        <card class="no-border-card card-surgery" body-classes="px-0 pb-3" footer-classes="pb-2"
          v-loading="loaders.fetching">
          <div slot="header">
            <h2 class="mt-2 manage-user-detail-heading">SURGERY</h2>
          </div>
          <div class="row mt--4 pl-4">
            <div class="col-6 pt-2 pl-3">
              <p class="manage-user-detail-text">Type Of Surgery</p>
              <p class="manage-user-detail-text">Initial Stage</p>
              <p class="manage-user-detail-text">Current Stage</p>
              <p class="manage-user-detail-text">Surgery Date</p>
            </div>
            <div class="col-6 pt-1">
              <p class="black_p">
                {{ user.type_of_surgery ? user.type_of_surgery : '-' }}
              </p>
              <p class="black_p">
                {{ getNameOfStage(user.inital_stage) }}
              </p>
              <p class="black_p">
                {{ getNameOfStage(user.current_stage) }}
              </p>
              <p class="black_p">
                {{ user.surgery_date ? formatDate(user.surgery_date) : '-' }}
              </p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-12">
        <patient-detail-messages />
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import PatientDetailMessages from "@/views/Components/Patients/PatientDetail/PatientDetailMessages";
import moment from 'moment'

export default {
  components: {
    BackButton,
    PatientDetailMessages,
  },
  data() {
    return {
      loaders: {
        fetching: false,
        email: false,
      },
      user: {},
      stages: {
        25: 'Considering Surgery',
        50: 'Pre-Op',
        75: 'Post-Op',
        100: 'Post-Op',
        125: 'Post-Op',
        150: 'Non-Surgical',
      },
    };
  },
  mounted() {
    this.getManageUsersById();
  },
  methods: {
    /**
     * Function is used to get user by id
     * 
     *  @return @void
     */
    async getManageUsersById() {
      this.loaders.fetching = true;
      let id = this.$route.params.id;
      try {
        let response = await this.$store.dispatch('ManageUsersModule/_getManageUsersById', { id });

        let {
          data: { data },
        } = response;
        this.user = data;
      } catch (error) {
        if(error?.response?.status == 404){
          this.$router.push({name:'admin.manage-users.index'});
        }

      }
      this.loaders.fetching = false;
    },
    /**
     * Function is used to send password reset email to patient
     * 
     *  @return @void
     */    
    async resendLoginDetails() {
      let vm = this;
      this.loaders.email = true;
      try {
        let response = await this.$store.dispatch('ManageUsersModule/_sendPasswordResetEmail', {fd:{
          patient_id: vm.user.id,
        }});

        vm.$notify.success({
          title: 'Success',
          message: 'Login email sent'
        });
      } catch (error) {
        var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }
      vm.loaders.email = false;

    },
    /**
     * Function is used to get stage name instead of stage code
     * 
     *  @param stage
     * 
     *  @return @string
     */    
    getNameOfStage(stage) {
      if (!stage) {
        return '-'
      }
      return this.stages[stage];
    },

  },
  computed: {
    /**
     *  Computed function to get Status in string instead of boolean
     * 
     */    
    getStatus() {
      return this.user.status === 0 ? 'Inactive' : 'Active';
    },
    /**
     *  Computed function to get Starting Weight to upto 2 decimal places if exists otherwise get -
     * 
     */    
    getStartingWeight() {
      return this.user.starting_weight ? (+this.user.starting_weight).toFixed(2) : '-';
    },
    /**
     *  Computed function to get Current Weight to upto 2 decimal places if exists otherwise get -
     * 
     */    
    getCurrentWeight() {
      return this.user.current_weight ? (+this.user.current_weight).toFixed(2) : '-';
    },
    /**
     *  Computed function to get Target Weight to upto 2 decimal places if exists otherwise get -
     * 
     */
    getTargetWeight() {
      return this.user.target_weight ? (+this.user.target_weight).toFixed(2) : '-'
    },
    /**
     *  Computed function to get created at in MMM D,YYY Format
     * 
     */
    getProgramJoinDate() {
      let date = '-';
      if (this.user && this.user.program_join_date) {
        date = moment(this.user.program_join_date, 'YYYY-MM-DD').format('MMM D, YYYY');
      }
      return date;
    }
  },
};
</script>
<style lang="scss">
.manage-users-show {
  .manage-user-name {
    text-align: left;
    font: normal normal 600 25px/34px Open Sans;
    letter-spacing: 0;
    color: #32325D;
    opacity: 1;
  }

  .manage-user-email {
    text-align: left;
    font: normal normal 600 17px/23px Open Sans;
    letter-spacing: 0;
    color: #8898AA;
    opacity: 1;
  }

  .manage-user-status {
    box-shadow: 0px 3px 2px #E9ECEF0D;
    border-radius: 20px;
    opacity: 1;
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0.48px;
    color: #FFFFFF;

    &.active {
      background: #94B527 0 0 no-repeat padding-box;
    }

    &.inactive {
      background: #BB2255 0 0 no-repeat padding-box;
    }
  }

  .manage-user-detail-heading {
    text-align: left;
    font: normal normal bold 16px/22px Open Sans;
    letter-spacing: 0;
    color: #172B4D;
    text-transform: uppercase;
    opacity: 1;
  }

  .manage-user-detail-text {
    text-align: left;
    font: normal normal normal 15px/19px Open Sans;
    letter-spacing: 0;
    color: #8898AA;
    padding-bottom: 2px;
    opacity: 1;
  }

  .no-border-card .card-footer {
    border-top: 0;
  }

  #basic-info-card .card-body {
    padding-top: 0 !important;
  }

  #basic-info-card .card-header {
    padding-bottom: 0 !important;
  }

  .card-header {
    border: none;
  }

  .card-weight {
    height: calc(45% - 30px);
  }

  .card-surgery {
    height: calc(55% - 30px);
  }


}
</style>  